body {
  background-color: #9AC;
  color: #333;
  font-family: Arial;
}

a {
  color: #36A;
  text-decoration: none;
}
a:hover {
  color: #249;
  text-decoration: underline;
}
a:visited {
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.title {
  font-size: 2.5rem;
}

.links {
  font-size: 1.5rem;
  /* width: 100%;
  text-align: center;
  border-bottom: 1px dashed #ACF;
  margin-bottom: 0.25rem; */
}

.description {
  font-size: 1rem;
  text-align: center;
}
